


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { mapState } from 'vuex';
// @ts-ignore
// import vueQr from 'vue-qr';
import xss from 'xss';
import _ from 'lodash';
import moment from 'moment';
import { blackList } from '@/util/blackFair';
import qs from 'qs';
import { ElForm } from 'element-ui/types/form';
import FairItem from '../home/component/FairItem.vue';
import Booth from './component/booth.vue';
import Route from './component/Route.vue';
import { initAliplayer } from './player';
import NewItem from './component/newItem.vue';
import JuMap from '~/components/Map/index.vue';
import MediaBox from '~/components/MediaBox/index.vue';
import FairTag from '~/components/Goods/fairTag.vue';
import FairUser from '~/components/FairUser/index.vue';
import Poster from './component/poster.vue';
import ResourceView from './component/resourceView.vue';
import dialogSubsidy from './component/dialogSubsidy.vue';
import {
  checkDevice,
  bucketCdnHost,
  bucketHost,
  getContent,
  MINI_HOST,
  sleep,
  message,
  baiduKey2,
  getXOssProcess,
  decryptAndReplace,
  handleVideoUrl,
  getWhiteScreenTime,
  handleNewsUrl,
  handleNodeServerError,
} from '~/util/common';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import 'swiper/css/swiper.min.css';
import 'vue-photo-preview/dist/skin.css';
import NewsItem2 from '~/pages/fair/component/NewsItem2.vue';
// import { serviceObj, serviceTicket } from '~/pages/fair/service';
// import HotelSearch from '~/components/Hotel/Booking.vue';
import HotelItem from '~/components/Hotel/HotelItem.vue';
// import AirTicketSearch from '~/components/AirTicket/Search.vue';
import { getCodeByName, getNameByCode } from '~/components/Hotel/city-data';
import { TraceCode } from '~/util/trace';
const env: any = require('~/config/env');

let handleScrollDebounce = () => {};
let resetNum: number = 0;

export default Vue.extend({
  name: 'fairDetail',
  components: {
    // vueQr,
    FairItem,
    Booth,
    Route,
    JuMap,
    NewItem,
    MediaBox,
    FairTag,
    FairUser,
    NewsItem2,
    // HotelSearch,
    HotelItem,
    // AirTicketSearch,
    Poster,
    ResourceView,
    dialogSubsidy,
  },
  // 校验params参数
  validate(ctx) {
    const id = _.get(ctx, 'params.id', null);
    return /^\d+$/.test(id);
  },
  filters: {
    dateFormat2(date: string) {
      return moment(new Date(date)).format('YYYY年MM月DD日');
    },

    newDateFormat(data: Record<string, any>) {
      if (data.startTime && data.endTime) {
        const startTime = moment(data.startTime).format('YYYY.MM.DD');
        const endTime = moment(data.endTime).format('MM.DD');
        return `${startTime}-${endTime}`;
      } else {
        return '时间待定';
      }
    },
    industry(data: string): string {
      let str = '展会';
      try {
        return data.split(',')[0] + str;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + str;
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
    // 用于将字符串中的数字进行转换
    formatNumber(value: string): string {
      // 如果输入值为空，则返回空字符串
      if (!value) return '';

      // 使用正则表达式匹配输入字符串中的数字和单位
      const match = value.match(/^(\d+)([^\d]+)$/);

      // 如果没有匹配到数字，则返回原始值
      if (!match) return value;

      // 获取匹配到的数字和单位
      const number = match[1];
      const unit = match[2];

      // 初始化结果字符串
      let result = '';

      // 将字符串数字转为数值进行比较
      const numericValue = parseInt(number, 10);

      // 数字转换逻辑
      if (numericValue >= 10000) {
        result = (numericValue / 10000).toFixed(1) + '万';
      } else if (numericValue >= 1000) {
        result = (numericValue / 1000).toFixed(1) + '千';
      } else {
        result = number;
      }

      // 拼接转换后的数字和原有的单位，并返回结果
      return result + unit;
    },
  },
  async asyncData(ctx) {
    try {
      const lang = _.get(ctx, 'query.lang', null);
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';

      const id = _.get(ctx, 'params.id', null);
      let apiErrorList: any = [];
      const res: any[] = await Promise.all([
        ctx.$axios.get(`/api/exhibition/${id}`),
        ctx.$axios.post(`/api/exhibitionGroupApi/group`, {
          id,
        }),
        ctx.$axios.post(`/api/exhibition/list`, {
          locale: langApi,
          selectTimeType: 1,
          exhibitionId: id,
          exhibitionType: 'CURRENT', // 同期展会
          pageNum: 1,
          pageSize: 30,
        }),
        ctx.$axios.post(`/api/exhibition/list`, {
          locale: langApi,
          selectTimeType: 1,
          exhibitionId: id,
          exhibitionType: 'SERIES', // 系列展会
          pageNum: 1,
          pageSize: 30,
        }),
        ctx.$axios.get(`/api/exhibition/boothInfo/${id}`),
        ctx.$axios.get(`/api/ticket/query/${id}`),
      ]);

      const [
        detailRes,
        sessionsRes,
        sameFairRes,
        logoFairRes,
        boothRes,
        ticketRes,
      ] = res;

      let detail: Record<string, any> = {};
      const industry: any[] = [];
      let industryId: any[] = [];
      let industryName: any[] = [];
      let isFocus = false; // 是否关注
      let remind = false; // 是否提醒
      // console.log('2716-',detailRes.data)
      if (detailRes.code === ResponseCode.BLACK_LOGIN) {
        return ctx.error({
          message: '先登录！',
          path: ctx.route.fullPath,
          statusCode: 504,
        });
      }
      if (detailRes.code != ResponseCode.SUCCESS) {
        apiErrorList.push({ code: detailRes.code, message: detailRes.message });
        handleNodeServerError(ctx, JSON.stringify(apiErrorList));
      }

      if (detailRes.code === ResponseCode.SUCCESS) {
        if ((detailRes.data ?? '') == '') {
          return ctx.error({
            message: '展会不存在！',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        if (detailRes.data.boothInfo) {
          detailRes.data.boothInfo = JSON.parse(detailRes.data.boothInfo);
        } else {
          detail.boothInfo = [];
        }
        detail = detailRes.data || {};
        detail.backgroundVideo = detail.backgroundVideo
          ? handleVideoUrl(detail.backgroundVideo)
          : detail.backgroundVideo;
        // console.log('2737-', detail.video.resourceUrl);
        detail.informationSecret = detailRes.informationSecret;

        detail.boothPrice1 = 0;
        detail.boothPrice2 = 0;
        if (detail.boothInfo && detail.boothInfo.length > 0) {
          detail.boothInfo.forEach((item: any) => {
            if (item.priceType === '标准展位') {
              detail.boothPrice1 = item.price;
            }
            if (item.priceType === '光地展位') {
              detail.boothPrice2 = item.price;
            }
          });
        }
        detail.exhibitionNotesList = [];
        if (detail.exhibitionNotes) {
          detail.exhibitionNotesList = detail.exhibitionNotes.split(',');
        }
        // online 1:上架 2:未上架
        if (
          (!_.isNil(detail.online) && detail.online !== 1) ||
          _.isNil(detailRes.data)
        ) {
          return ctx.error({
            message: '已下架', // Page not found
            path: ctx.route.fullPath,
            statusCode: 404, // 404 503
          });
        }
        industryId = [
          detail.industry1,
          detail.industry2,
          detail.industry3,
        ].filter((id) => !!id);
        industryName = detail.industry?.split(',') || [];
        industryName.forEach((name, index) => {
          industry.push({
            id: industryId[index],
            name,
          });
        });
        isFocus = detailRes.data?.follow; // 1: 已关注 0: 未关注
        remind = detailRes.data.follow; // 1: 提醒 0: 未提醒
      } else {
        apiErrorList.push({ code: detailRes.code, message: detailRes.message });
        handleNodeServerError(ctx, JSON.stringify(apiErrorList));
        // return ctx.error({
        //   message: '展会不存在！',
        //   path: ctx.route.fullPath,
        //   statusCode: 404,
        // }); //
      }

      // 多届展会
      let sessionsFair = [];
      if (sessionsRes.code === ResponseCode.SUCCESS) {
        sessionsRes.data.groupList?.forEach((item: any) => {
          const startTime = item.startTime?.slice(0, 10).replace(/-/g, '.');
          const endTime = item.endTime?.slice(5, 10).replace(/-/g, '.');
          item.lunchTime = startTime + '-' + endTime;
        });
        sessionsFair = sessionsRes.data?.groupList?.filter(
          (item: any) => !!item.exhibitionName
        );
      }

      // 同期展会
      let sameFair = {};
      if (sameFairRes.code === ResponseCode.SUCCESS) {
        sameFair = sameFairRes.data;
      }

      // 系列展会
      let logoFair = {};
      if (logoFairRes.code === ResponseCode.SUCCESS) {
        logoFair = logoFairRes.data;
      }

      // 展会价格
      let booth = {};
      if (boothRes.code === ResponseCode.SUCCESS) {
        booth = boothRes.data;
      }

      const otherRes: any[] = await Promise.all([
        ctx.$axios.post('/api/exhibition/list', {
          locale: langApi,
          selectTimeType: 1,
          industryId: _.get(industry, '[0].id', null), // 行业展会
          regionType: detail.regionType,
          pageNum: 1,
          pageSize: 8,
        }),
        ctx.$axios.post('/api/newsApi/homePageList', {
          locale: langApi,
          exhibitionId: id,
          industryId: _.get(industry, '[0].id', null), // 推荐资讯
          newsType: 1,
          pageNum: 1,
          pageSize: 13,
        }),
        // ctx.$axios.post('/api/newsApi/homePageList', {
        //   // 最新资讯
        //   locale: langApi,
        //   newsType: 1,
        //   pageNum: 1,
        //   pageSize: 7,
        // }),
        // ctx.$axios.post('/api/exhibition/page', {
        //   // 最新展会
        //   locale: langApi,
        //   pageNum: 1,
        //   pageSize: 7,
        // }),
        ctx.$axios.post(`/api/specialTopicApi/resourceId`, {
          type: 0,
          resourceId: id,
        }),
        ctx.$axios.post(`/api/followeApi/getExhibitionFollowedUsers`, {
          exhibitionId: id,
          pageNum: 1,
          pageSize: 12,
        }),
        ctx.$axios.post(`/api/newsApi/getNewsbyExhibitionId/`, {
          locale: langApi,
          exhibitionId: id,
          pageNum: 1,
          pageSize: 13,
        }),
      ]);

      const [
        industryFairRes,
        newsRes,
        // newNewsRes,
        // newFairRes,
        tagRes,
        followRes,
        newsByFairIdRes,
      ] = otherRes;

      // 行业展会
      let industryFair = {};
      if (industryFairRes.code === ResponseCode.SUCCESS) {
        industryFair = industryFairRes.data;
      }

      // 展会资讯
      let fairNews: any[] = [];
      if (newsByFairIdRes.code === ResponseCode.SUCCESS) {
        fairNews = newsByFairIdRes.data.records.map((n: any) => ({
          newsId: n.id,
          viewUrl: n.viewUrl,
          newsName: n.title,
          views: n.viewCount,
          logo: n.coverImg,
          publishTime: n.publishTime,
          description: n.description,
        }));
      }

      // 行业资讯
      let news: any[] = [];
      if (
        newsRes.code === ResponseCode.SUCCESS &&
        newsRes.data &&
        newsRes.data.records
      ) {
        news = newsRes.data.records.map((item: Record<string, any>) => ({
          newsId: item.newsId,
          viewUrl: item.viewUrl,
          newsName: item.newsName,
          views: item.views,
          logo: item.logo,
          publishTime: item.publishTime,
          description: item.description,
        }));
      }

      // 最新资讯
      // let newNews = {};
      // if (newNewsRes.code === ResponseCode.SUCCESS && newNewsRes.data && newNewsRes.data.records) {
      //   newNews = newNewsRes.data.records.map(
      //     (item: { newsId: any; newsName: any; views: any }) => ({
      //       newsId: item.newsId,
      //       newsName: item.newsName,
      //       views: item.views,
      //     })
      //   );
      // }

      // 最新展会
      // let newFair = {};
      // if (newFairRes.code === ResponseCode.SUCCESS) {
      //   newFair = newFairRes.data.records.map(
      //     (item: { exhibitionId: any; exhibitionName: any; views: any }) => ({
      //       exhibitionId: item.exhibitionId,
      //       exhibitionName: item.exhibitionName,
      //     })
      //   );
      // }

      let ticketPrice = {};
      if (ticketRes.code === ResponseCode.SUCCESS) {
        ticketPrice = ticketRes.data;
      }

      let tag = [];
      if (tagRes.code === ResponseCode.SUCCESS) {
        tag = tagRes.data;
      }

      const follow: { data: any[]; total: number } = {
        data: [],
        total: 0,
      };
      if (followRes.code === ResponseCode.SUCCESS) {
        follow.data = followRes.data?.records || [];
        follow.total = followRes.data?.total || 0;
      }

      // 内链
      let innerChainActive: number = 0;
      let paramsIndustry: any[] = [];
      if (detail.firstIndustryId) paramsIndustry.push(detail.firstIndustryId);
      if (detail.industry1) paramsIndustry.push(detail.industry1);
      if (detail.industry2) paramsIndustry.push(detail.industry2);
      if (detail.industry3) paramsIndustry.push(detail.industry3);
      const innerChainAllRes: any = await Promise.all([
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门地区',
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门行业',
          industryIdList: paramsIndustry,
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门展会',
          industryIdList: paramsIndustry,
          pageSize: 16,
          // locale: detail.locale,
        }),
      ]);

      const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
        innerChainAllRes;
      let innerChainAreaList: any[] = [];
      let innerChainIndustryList: any[] = [];
      let innerChainExhList: any[] = [];

      if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
        innerChainAreaList = innerChainAreaRes.data.records;
      }
      if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
        innerChainIndustryList = innerChainIndustryRes.data.records;
        let len = innerChainIndustryList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门行业',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainArea2Res] = ctxIndustry;
          if (innerChainArea2Res.code === ResponseCode.SUCCESS) {
            innerChainIndustryList = innerChainIndustryList.concat(
              innerChainArea2Res.data.records
            );
          }
        }
      }
      if (innerChainExhRes.code === ResponseCode.SUCCESS) {
        innerChainExhList = innerChainExhRes.data.records;
        let len = innerChainExhList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门展会',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainExh2Res] = ctxIndustry;
          if (innerChainExh2Res.code === ResponseCode.SUCCESS) {
            innerChainExhList = innerChainExhList.concat(
              innerChainExh2Res.data.records
            );
          }
        }
      }

      return {
        lang,
        langApi,
        detail,
        sessionsFair,
        industry,
        isFocus,
        remind,
        sameFair,
        logoFair,
        booth,
        industryFair,
        news,
        fairNews,
        // newNews,
        // newFair,
        ticketPrice,
        attribution: `&copy; <span>${
          _.get(detail, 'pavilionInfo.pavilionName', null) ||
          _.get(detail, 'pavilionInfo.pavilionEnglishName', null)
        }</span>`,
        center: [
          _.get(detail, 'pavilionInfo.latitude', 0),
          _.get(detail, 'pavilionInfo.longitude', 0),
        ],
        tag,
        follow,
        isSdkReady: false, // 客戶sdk初始化判断
        showView: false,
        routeHash: '',
        innerChainActive,
        innerChainAreaList,
        innerChainIndustryList,
        innerChainExhList,
      };
    } catch (e: any) {
      return ctx.error({
        message: e.message,
        path: ctx.route.fullPath,
        statusCode: 500,
      });
    }
  },
  data(): any {
    return {
      isLogin: false,
      rateValue: 0,
      rateData: {
        scaleScore: 0,
        specialityScore: 0,
        evaluateScore: 0,
        audienceScore: 0,
        marketScore: 0,
      },
      showPoster: false,
      resourceList: [],
      showMessage: true,
      datePosition: {},
      messageList: [],
      newPeriodical: {},
      player: '',
      isLoading: true,
      videoVisible: false,
      blackList,
      ticketService: [],
      detail: {},
      activeName: 'first',
      showTitle: true,
      switchValue: true,
      fraction: 3.7,
      current: '展会数据',
      showEvaluation: false, // 控制评价弹框
      showMore: false,
      sameSwiperOption: {
        slidesPerView: 3.2,
        spaceBetween: 10,
        autoplay: 3000, // l轮播间隔时间
        loop: false, //
      },
      swiperOption: {
        slidesPerView: 3.2,
        spaceBetween: 10,
        autoplay: 3000, // l轮播间隔时间
        loop: false, // 是否自动轮播
      },
      serviceList: [],
      banner: [],
      areaExhList: [],
      hotelList: [],
      dialogShow: false,
      dialogTitle: '',
      dialogImg: '',

      display: '',
      displayCode: '',
      applyVisible: false,
      applyTitle: '',
      downloadList: [
        {
          text: this.$t('exhibitionDetail.exhibitionInvitationLetter'),
          url: require('@/assets/images/zzh.jpg'),
        },
        {
          text: this.$t('personal.right.electronic_journal'),
          url: require('@/assets/images/zsml.jpg'),
        },
        {
          text: this.$t('exhibitionDetail.boothMap'),
          url: require('@/assets/images/zwt.jpg'),
        },
        {
          text: this.$t('exhibitionDetail.buyerData'),
          url: require('@/assets/images/mjsj.jpg'),
        },
      ],
      ruleForm: {
        companyName: '',
        linkman: '',
        remark: '',
        email: '',
        boothType: undefined,
        linkmanPhoneNumber: '',
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        boothType: [
          { required: true, message: '价格不能为空', trigger: 'blur' },
        ],
        linkmanPhoneNumber: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
        ],
      },
      headimg: require('@/assets/images/headimg.jpg'),
      reptileShow: false,
      messageTitle: '',
      mapDialogShow: false,
      showView: false,
      routeHash: '',
      boothServiceList: [],
      ticketServiceList: [],
      showSubsidy: false,
    };
  },
  watch: {
    stateIsLogin(v) {
      this.isLogin = v;
    },
  },
  computed: {
    ...mapState({
      stateIsLogin: (state: any) => state.global.isLogin,
    }),

    photos(): any[] {
      try {
        const newArr: any[] = [];
        if (this.detail.photos) {
          this.detail.photos.slice(1).map((item: { resourceUrl: any }) => {
            newArr.push(item.resourceUrl);
          });
        }
        return newArr;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return this.detail.pic;
      }
    },
    qrcode(): string {
      const { id } = this.$route.params;
      const openId = _.get(this.$store, 'state.global.openId', '');
      const tel = _.get(this.$store, 'state.global.user.userName', '');
      const content = `${MINI_HOST}?exhibitionId=${id}&openId=${openId}&tel=${tel}`;
      return content;
    },
    industryList(): string[] {
      if (this.detail.industry) {
        try {
          const industry = this.detail.industry?.split(',');
          return industry;
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    tips(): any[] {
      try {
        const { tips } = this.$data.ticketPrice;
        return JSON.parse(tips).slice(0, 2);
      } catch (error) {
        return [];
      }
    },
    hasPhoneOrEmail(): string {
      const val: string[] = [];
      const { containPhoneEmail = [] } = this.newPeriodical;
      if (containPhoneEmail.includes('0')) {
        val.push('电话');
      }
      if (containPhoneEmail.includes('1')) {
        val.push('邮箱');
      }
      if (containPhoneEmail.includes('2')) {
        val.push('官网');
      }
      return val.length > 0 ? `含${val.join('、')}` : '';
    },
    pavilionPicture(): string[] {
      const val = _.get(this.detail, 'pavilionInfo.picture', '');
      try {
        return val.split(';');
      } catch (e) {
        return [val];
      }
    },
    optionName(): string {
      let pavilionName = _.get(this, 'detail.pavilionInfo.pavilionName', '');
      pavilionName = pavilionName?.replace(/（/g, '(')?.replace(/）/g, ')');

      return pavilionName;
    },
    hotelQuery(): string {
      const query = {
        adult: 1,
        checkin: moment().format('YYYY-MM-DD'),
        checkout: moment().add(3, 'days').format('YYYY-MM-DD'),
        children: 0,
        display: this.detail.area,
        cityCode: getNameByCode(this.detail.area),
        crn: 1,
        optionName: this.optionName,
        starlist: '4,5',
      };

      return qs.stringify(query);
    },
  },
  created(): void {
    this.getAreaExh();
    this.getRate();

    if (this.$route.hash == '#view') {
      this.getResource();
      this.routeHash = this.$route.hash;
      this.showView = this.$route.hash == '#view' ? true : false;
    }
  },
  mounted(): void {
    this.checkFairLanguage();
    this.getOrderMessage();
    this.exhibitionViewApiSave();
    this.getBanner();
    if (checkDevice() == 'h5') {
      let url = `https://m.jufair.com/exhibition/${this.$route.params.id}.html`;
      window.location.replace(url);
    }
    this.getService();
    this.isLogin = this.stateIsLogin;
    // 视频播放结束后隐藏视频
    let videoEl: any = document.getElementById('video');
    videoEl.addEventListener('ended', function () {
      // 在视频播放结束时执行的其他操作
      videoEl.style.display = 'none';
    });

    if (process.client) {
      // 解密展会介绍
      this.detail.introduction = decryptAndReplace(
        this.detail.introduction,
        this.detail.informationSecret
      );
    }
    // 展会详情电梯
    const first: any = document.getElementById('pane-first');
    const title: any = document.getElementById('title');
    if (this.routeHash != '#view') {
      if (first && title) {
        title.style.height = first.offsetHeight + 'px';
      }

      handleScrollDebounce = _.debounce(this.handleScroll, 150);

      window.addEventListener('scroll', handleScrollDebounce);
    }

    // 视频
    this.getPlayAuth();
    this.fetchNewPeriodical();

    // 酒店
    // if (this.detail.regionType == '0') {
    //   this.fetchHotel();
    // }

    // 计算订单消息位置
    try {
      const parentEl = this.$refs.exh_detail_right;
      const dateEl = this.$refs.date;
      const parentRect = parentEl?.getBoundingClientRect() || {};
      const dateRect = dateEl?.getBoundingClientRect() || {};
      this.datePosition = {
        // top: dateRect.top - parentRect.top,
        top: 20, // dateRect.top - parentRect.top - 55, //
        left: dateRect.left - parentRect.left,
        bottom: dateRect.bottom - parentRect.top,
        right: dateRect.right - parentRect.left,
      };
    } catch (e) {
      this.showMessage = false;
    }
    getWhiteScreenTime()
    // 获取海报
    // this.getPoster()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', handleScrollDebounce);
  },
  methods: {
    getXOssProcess,
    unescape,
    xss,
    getContent,
    handleNewsUrl,
    moment,
    // 中文域名打开英文展会,则跳转到英文域名; 英文域名打开中文展会,则跳转到中文域名;
    checkFairLanguage() {
      let hostNameCn: any = env['pro'].HOST_NAME;
      let hostNameEn: any = env['pro'].HOST_NAME_EN;
      let hostName: any = window.location.origin; // 当前域名
      const routeParams = this.$route.params;
      const routeQuery = this.$route.query;
      let newHost: any = ''; // 要跳转的地址
      let t1: any =
        hostName.includes('en.jufair.com') &&
        (!this.detail.hasOwnProperty('locale') ||
          !this.detail.locale ||
          this.detail.locale == 'CN'); // 英文域名 && 展会语言是CN
      let t2: any =
        hostName.includes('www.jufair.com') &&
        this.detail.hasOwnProperty('locale') &&
        this.detail.locale == 'EN';
      let t3: any =
        hostName.includes('pre-pc.jufair.com') &&
        this.detail.hasOwnProperty('locale') &&
        this.detail.locale == 'EN';
      let t4: any =
        hostName.includes('192.168.1.138') &&
        this.detail.hasOwnProperty('locale') &&
        this.detail.locale == 'EN';
      // 没有locale || locale 为空则认为是中文展会
      if (
        hostName.includes('en.jufair.com') &&
        (!this.detail.locale || !this.detail.hasOwnProperty('locale'))
      ) {
        window.location.replace(
          `https://www.jufair.com/exhibition/${routeParams.id}.html`
        );
      }

      if ((t1 || t2) && !routeQuery.hasOwnProperty('debug')) {
        if (t1) newHost = hostNameCn;
        if (t2) newHost = hostNameEn;
        if (t3) newHost = hostNameEn;
        if (t4) newHost = hostNameEn;
        let newUrl: any = `${newHost}/exhibition/${routeParams.id}.html`;
        window.location.replace(newUrl); // 重定向到正确的域名
      }
    },
    handleOnlineService() {
      let serviceEl: any = document.getElementById('k_s_ol_chatWinSm_imgDiv'); // ceshiidmap k_s_ol_chatWinSm
      serviceEl.click();
    },
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },
    handleSubsidy() {
      this.showSubsidy = true;
    },
    formatNumber(value: string) {
      // 如果输入值为空，则返回空字符串
      if (!value) return '';
      if (!this.lang || this.lang == 'zh') {
        // 使用正则表达式匹配输入字符串中的数字和单位
        const match = value.match(/^(\d+)([^\d]+)$/);

        // 如果没有匹配到数字，则返回原始值
        if (!value) return value;

        // 获取匹配到的数字和单位
        const number = value; // match[1];

        // 初始化结果字符串
        let result = '';

        // 将字符串数字转为数值进行比较
        const numericValue = parseInt(number, 10);

        // 数字转换逻辑
        if (numericValue >= 10000) {
          result = (numericValue / 10000).toFixed(1) + '万';
        } else if (numericValue >= 1000) {
          result = (numericValue / 1000).toFixed(1) + '千';
        } else {
          result = number;
        }
        // 拼接转换后的数字和原有的单位，并返回结果
        return result;
      } else {
        return value;
      }
    },

    getPercent(val: number) {
      return (val / 5) * 100 + '%';
    },
    setNavIndustry(data: string): string {
      let str = !this.lang || this.lang == 'zh' ? '展会' : ' Exhibition';
      try {
        return data.split(',')[0] + str;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + str;
      }
    },
    // 获取客服
    async getService() {
      // return false
      const res: any = await this.$axios.post(`/api/hr/staff/services`, {
        exhibitionId: this.detail.exhibitionId,
        industry: this.detail.industry1,
        skillCodes: ['exOnlineSale', 'ticketOnlineSale'],
      });
      if (res.code === ResponseCode.SUCCESS) {
        let list = res.data;
        list.map((item: any) => {
          item.avatar = `${item.avatar}?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fill,w_70,h_70/sharpen,100`;
        });
        let saleList: any = list.filter((i: any) =>
          i.skillList.some((x: any) => x.skillCode == 'exOnlineSale')
        );
        this.boothServiceList = saleList || [];

        // 门票客服
        let newList: any = list.filter((i: any) =>
          i.skillList.some((x: any) => x.skillCode == 'ticketOnlineSale')
        );
        this.ticketServiceList = newList || [];
      }
    },
    // async getPoster(){
    //   const res = await this.$axios.get(`/api/poster/getLatestPoster?tag=${this.detail.posterTag}`)
    // },
    handlePoster() {
      this.showPoster = true;
    },
    handleContent() {
      window.open(
        `/exhibition/${this.detail.exhibitionId}.html#content`,
        '_blank'
      );
    },
    handleView() {
      window.open(
        `/exhibition/${this.detail.exhibitionId}.html#view`,
        '_blank'
      );
      // return false
      // this.$router.replace({

      // })
    },
    // 获取评分
    async getRate() {
      const res: any = await this.$axios.post(
        `/api/exhibitionScoreApi/getByEhibitionId`,
        {
          exhibitionId: this.detail.exhibitionId,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        let count: number = 0;
        if (!res.data) return false;
        for (let i in this.rateData) {
          this.rateData[i] = res.data[i];
          count += res.data[i];
        }
        this.rateValue = Number((count / 5).toFixed(1));
      }
    },
    // 获取展会图片(展会视图)
    async getResource() {
      const res: any = await this.$axios.post(`/api/exhibitionResource/get`, {
        exhibitionId: this.detail.exhibitionId,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.resourceList = res.data;
      }
    },

    getAddress() {
      this.mapDialogShow = true;
      // this.$windowOpen(
      //   `/pavilion/${this.detail.pavilionInfo.id}.html`,
      //   '_blank'
      // );
    },
    toExhibitionDel(id: number | string) {
      window.open(`/exhibition/${id}.html`, '_blank');
    },
    querySearchAsync: _.debounce(async function (
      str: string,
      cb: (val: any[]) => void
    ) {
      // @ts-ignore
      const { $axios } = this;
      let data = [];
      if (str && str.trim()) {
        const res: any = await $axios.post(
          '/api/companyApi/keyWord',
          str.trim(),
          {
            headers: {
              'Content-Type': 'text/plain',
            },
          }
        );
        data = res.data.map((r: string) => ({ value: r }));
      } else {
        data = [];
      }
      cb(data);
    },
    300),
    goToInformation() {
      this.$windowOpen(`/expoinfo/${this.$route.params.id}.html`, '_blank');
    },
    // 下载申请招展函
    apply(name: string) {
      this.applyTitle = _.cloneDeep(name);
      if (name === '展商/电子会刊查看') {
        // this.$router.push(
        //   `/periodical/${this.$route.params.id}.html`
        // );
        this.$windowOpen(`/periodical/${this.$route.params.id}.html`, '_blank');
      } else {
        this.applyVisible = true;
      }
    },
    handleClose() {
      this.applyVisible = false;
    },
    submit() {
      const form = this.$refs.rule as ElForm;
      form.validate((valid) => {
        if (valid) {
          this.fecthSave();
        } else {
          console.log('error submit!!');
        }
      });
    },
    async fecthSave() {
      const res: any = await this.$axios.post('/api/exhibition/applyBooth', {
        ...this.ruleForm,
        exhibitionId: +this.detail.exhibitionId,
        promotionLink: window.location.href,
      });
      if (res.code === ResponseCode.SUCCESS) {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        this.applyVisible = false;
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '服务咨询成功',
          msg: '专业客服将在1-2个工作日内电话与您联系。',
        });
      }
    },
    resetForm() {
      const $form = this.$refs.rule as ElForm;
      $form?.resetFields();
      this.applyVisible = false;
    },
    async getOrderMessage() {
      const id = _.get(this, '$route.params.id', null);
      if (!id) return;
      const res = await this.$axios.post(
        `/api/todayMessagesApi/getMessages/${id}`
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.messageList = res.data || [];
      }
    },
    getBusiness(tag: any) {
      if (tag === 'visa') {
        this.$router.push(`/visa-0-0-0-0-1/`);
      } else {
        window.open(`${this.$config.HOTEL}`, '_self');
      }
    },
    handleShowMini() {
      this.dialogShow = true;
      this.dialogTitle = this.$t('footer.scan_openApplet');
      this.dialogImg = require('~/assets/images/juzhan-mini.jpg');
    },

    // 获取banner
    async getBanner() {
      const res: any = await this.$axios.post(`/api/bannerApi/page`, {
        type: 2,
        channel: 0,
        industry1: this.detail.firstIndustryId,
        industry2: this.detail.industry1,
        areaType: this.detail.regionType,
      });
      if (res.code === ResponseCode.SUCCESS) {
        for (let i = 0; i < res.data.length; i++) {
          const url = res.data[i].bannerUrlList;
          const link = res.data[i].bannerLinkListPc;
          this.banner.push({
            url,
            link,
          });
        }
      }
    },
    // 浏览量
    async exhibitionViewApiSave() {
      const viewSave: any = await this.$axios.post(`/api/exhibitionViewApi/save`, {
        exhibitionId: this.detail.exhibitionId,
      });
      // if (res.code === ResponseCode.SUCCESS) {
        
      // }
    },

    // 获取当前国家同行业展会
    async getAreaExh() {
      const res: any = await this.$axios.post(`/api/exhibition/list`, {
        locale: this.langApi,
        industryId: this.detail.firstIndustryId,
        areaType: this.detail.regionType,
        area: this.detail.pavilionInfo.areaId,
        pageNum: 1,
        pageSize: 10,
      });
      if (res.code === ResponseCode.SUCCESS) {
        const list = res.data.records;
        this.areaExhList = list.splice(1, 9);
      }
    },
    // 客服SDK初始化
    // 客服SDK初始化
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },
    // 点击打开聊天窗
    openkefu(chat: string) {
      if (chat) {
        if (this.isSdkReady) {
          window.ysf('open');
        }
      } else {
        window.open(chat);
      }
    },
    async fetchNewPeriodical() {
      const id = _.get(this, '$route.params.id', null);
      const res = await this.$axios.get(
        `/api/periodicalApi/getNewPeriodical/${id}`
      );

      if (res.code === ResponseCode.SUCCESS && res.data) {
        this.newPeriodical = {
          ...res.data,
          containPhoneEmail: res.data.containPhoneEmail?.split(',') || [],
        };
      }
    },
    handleToApplyByType(type: string) {
      const id = _.get(this, '$route.params.id', null);
      this.$router.push(`/exhibition/apply/${id}.html?type=${type}`);
    },
    openVideo() {
      if (!this.isLogin) {
        return this.$login.show();
      }
      this.videoVisible = true;
      this.$nextTick(() => {
        this.getPlayAuth();
      });
    },
    handlePreview() {
      if (!this.isLogin) {
        return this.$login.show();
      }
    },
    closeVideo() {
      this.player.dispose();
      this.videoVisible = false;
    },
    // isLogin
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    // 显示购买弹窗
    async showPay() {
      if (this.shouldLogin()) {
        const res = await this.$axios.get(
          `api/pay/queryOrderStatus/${this.detail.exhibitionId}/1`
        );
        if (res.code === ResponseCode.SUCCESS) {
          // 订单状态 1-未支付 2-已支付 3-已提交 4-已完成
          if (_.get(res, 'data.status', null) === 2) {
            this.showOrderInfo(res.data);
          } else {
            this.$refs.payDesposit.dialogVisible = true;
          }
        }
      }
    },
    // 显示填写购买信息框
    showOrderInfo(data: any) {
      if (this.shouldLogin()) {
        this.$refs.orderInfo.dialogVisible = true;
        this.$refs.orderInfo.orderData = data;
      }
    },
    // 显示评价弹窗
    showEva() {
      if (this.shouldLogin()) {
        this.$refs.evaluation.dialogVisible = true;
      }
    },
    // 滚动事件
    handleScroll() {
      const menuDomList = document.querySelectorAll('a[data-name]');
      const { scrollTop } = document.documentElement;

      const res = Array.from(menuDomList).reduce((current, next) => {
        const nextTop = this.getDisTop(next);
        const top = nextTop - scrollTop;
        if (top > 20) {
          return current;
        } else {
          return next;
        }
      }, menuDomList[0]);

      const name = res.getAttribute('data-name');
      this.setCurrent(name);
    },
    // 获取元素距离页面顶部的距离
    getDisTop(element: any) {
      let realTop = element.offsetTop;
      let parent = element.offsetParent;
      while (parent !== null) {
        realTop += parent.offsetTop;
        parent = parent.offsetParent;
      }
      return realTop;
    },
    // tabs 切换
    handleClick() {
      if (this.activeName === 'first') {
        this.showTitle = true;
      } else {
        this.showTitle = false;
      }
    },
    // 设置当前显示的titile
    setCurrent(title: string) {
      this.current = title;
    },
    // 控制进度条不显示百分比
    format() {
      return '';
    },
    // 展会提醒
    async handleRemind() {
      if (this.shouldLogin()) {
        const id = _.get(this, '$route.params.id', null);
        if (!/\d+/.test(id)) {
          message.call(this, {
            delay: 3000,
            title: `展会Id:${id}不合法!`,
            type: 'error',
          });
          return;
        }
        const res: any = await this.$axios.post(
          `/api/exhibition-follow/follow`,
          { exhibitionId: id }
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.remind = !this.remind;
          const title = !this.remind ? '取消通知成功!' : '添加通知成功!';
          message.call(this, {
            delay: 3000,
            title,
            type: 'success',
          });
          // this.$toast(!this.remind ? '取消通知成功!' : '添加通知成功!', 3000);
        }
      }
    },
    // 关注展会
    async handleFollowFair() {
      await this.$store.commit(
        'user/setOrigins',
        location.origin + this.$route.fullPath
      );
      await this.$store.commit('user/setAction', 4);
      await this.$store.commit('user/setIndustryId', this.detail.industry1);

      if (this.shouldLogin()) {
        const id = _.get(this, '$route.params.id', null);
        // const res = await this.$axios.get(`/api/user/exhibition/${id}`);
        const res: any = await this.$axios.post(
          `/api/exhibition-follow/follow`,
          { exhibitionId: id }
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.isFocus = !this.isFocus;
        }
      }
    },
    handleNoTicket() {
      this.$message.warning('门票已售罄!');
    },
    // 阅读会刊
    readPeriodical() {
      // const token = this.$cookies.get('access_token');
      // if (!token) {
      //   await this.$store.commit(
      //     'user/setOrigins',
      //     location.origin + this.$route.fullPath
      //   );
      //   await this.$store.commit('user/setAction', 3);
      //   await this.$store.commit('user/setIndustryId', this.detail.industry1);
      //
      //   this.toLogin({
      //     to: `/periodical/${this.$route.params.id}.html`,
      //   });
      //   return;
      // }
      this.$windowOpen(`/periodical/${this.$route.params.id}.html`, '_blank');
      // 埋点
      window.trace?.upload({
        module: TraceCode.MO_FAIR_PERIODICAL,
        bizId: this.$route.params.id,
        callbackCode: TraceCode.CC_APPLY_PERIODICAL,
      });
      // this.$router.push(`/periodical/${this.$route.params.id}.html`);
    },
    goToVisa(areaId: any) {
      // this.$router.push(`/visa-0-${areaId}-0-0-1`);
      window.open(`/visa-0-${areaId}-0-0-1`, '_blank');
    },
    // 门票预订
    async handleToTicket() {
      if (this.detail.thirdUrl) {
        const token = this.$cookies.get('access_token');
        if (!token) {
          await this.$store.commit(
            'user/setOrigins',
            location.origin + this.$route.fullPath
          );
          await this.$store.commit('user/setAction', 2);
          await this.$store.commit('user/setIndustryId', this.detail.industry1);

          this.$login.show();
        } else {
          window.trace?.upload({
            module: TraceCode.MO_FAIR_TICKET,
            bizId: this.$route.params.id,
            callbackCode: TraceCode.CC_TICKET_OUT,
          });
          window.open(this.detail.thirdUrl, '_blank');
        }
      } else {
        window.trace?.upload({
          module: TraceCode.MO_FAIR_TICKET,
          bizId: this.$route.params.id,
          callbackCode: TraceCode.CC_TICKET_IN,
        });
        this.$windowOpen(`/ticket/${this.detail.exhibitionId}.html`, '_blank');
        // window.open(
        //   `/ticket/${this.detail.exhibitionId}.html`,
        //   '_blank'
        // );
      }
    },
    // 申请展位
    handleApply(_tag: number) {
      // const token = this.$cookies.get('access_token');
      // if (!token) {
      //   await this.$store.commit(
      //     'user/setOrigins',
      //     location.origin + this.$route.fullPath
      //   );
      //   await this.$store.commit('user/setAction', 1);
      //   await this.$store.commit('user/setIndustryId', this.detail.industry1);
      //
      //   this.toLogin({ to: `/exhibition/apply/${this.$route.params.id}.html` });
      //   return;
      // }
      // this.$router.push({
      //     name:'Child',
      //     params:{
      //       id:123
      //     }
      // })
      // 1.展位图
      this.$windowOpen(
        `/exhibition/apply/${this.$route.params.id}.html`,
        '_blank'
      );
      // this.$router.push(
      //   `/login?redirect=${encodeURIComponent(this.$route.fullPath)}`
      // );
      // 埋点
      window.trace?.upload({
        module: TraceCode.MO_FAIR_BOOTH,
        bizId: this.$route.params.id,
        callbackCode: TraceCode.CC_APPLY_BOOTH,
      });
      // window.open(`/exhibition/apply/${this.$route.params.id}.html`, '_blank');
    },
    handleToTRip() {
      window.open(`/exhibition/trip/${this.$route.params.id}.html`, '_blank');
    },
    // 查看更多
    handleShowrticle() {
      this.showMore = !this.showMore;
      if (!this.showMore) {
        setTimeout(() => {
          this.$refs.serverStep.scrollIntoView(true);
        }, 300);
      }
    },
    // 获取playauth
    async getPlayAuth() {
      if (process.server) return;
      const el = document.getElementById('J_prismPlayer');
      if (!el && resetNum < 10) {
        await sleep(1000);
        this.getPlayAuth();
        resetNum = resetNum + 1;
        return;
      }
      this.isLoading = false;
      const resourceUrl = _.get(this.detail, 'video.resourceUrl', '');
      if (!resourceUrl) return false;
      let vid;
      try {
        vid = JSON.parse(resourceUrl)[0];
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        console.error(error);
      }

      if (!vid) return;
      vid = handleVideoUrl(vid);
      if (
        vid.includes('.mp4') ||
        vid.includes('.m3u8') ||
        vid.includes('.m4v')
      ) {
        this.player = initAliplayer(
          'mp4',
          vid,
          ''
          // this.$options.filters.cover(this.detail.pic)
        );
      } else {
        const res = await this.$axios.get(`/api/exhibition/getPlayAuth/${vid}`);
        if (res.code === ResponseCode.SUCCESS) {
          this.playauth = res.data;
          this.player = initAliplayer(
            'm3u8',
            vid,
            res.data
            // this.$options.filters.cover(this.detail.pic)
          );
        }
      }
    },
    toLogin() {
      // const { fullPath } = this.$route;
      this.$login.show();
      // this.$router.push({
      //   path: `/login?from=${fullPath}&redirect=${encodeURIComponent(obj.to)}`,
      // });
      sessionStorage.setItem('ticketForm', JSON.stringify(this.ruleForm));
      sessionStorage.setItem(
        'invoiceRuleForm',
        JSON.stringify(this.invoiceRuleForm)
      );
    },
    toExposer() {
      // this.$refs.exposer?.scrollIntoView(true);
      this.$windowOpen(`/community/${this.$route.params.id}.html`, '_blank');
    },
    toCommunity() {
      this.$windowOpen(`/community/${this.$route.params.id}.html`, '_blank');
    },
    async handleFollowChange(val: number) {
      const res = await this.$axios.post(
        `/api/followeApi/getExhibitionFollowedUsers`,
        {
          exhibitionId: this.detail.exhibitionId,
          pageNum: val,
          pageSize: 12,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.$set(this, 'follow', {
          data: res.data.records,
          total: res.data.total,
        });
      }
    },
    async fetchHotel() {
      const { longitude, latitude, cityName } = this.detail.pavilionInfo || {};

      const getLocationInfo = async () => {
        if (cityName) {
          return { display: cityName, displayCode: getCodeByName(cityName) };
        } else if (longitude && latitude) {
          try {
            const locationRes = await this.$axios.post(
              `/baidu_api/reverse_geocoding/v3/?ak=${baiduKey2}&output=json&coordtype=wgs84ll&location=${latitude},${longitude}`
            );

            if (locationRes.status === 0) {
              const { city, province } = locationRes.result.addressComponent;
              const display = !city ? province : city;
              return { display, displayCode: getCodeByName(display) };
            }
          } catch (e) {
            console.error(e);
          }
        }
        return { display: null, displayCode: null };
      };

      const { display, displayCode } = await getLocationInfo();

      if (!display || !displayCode) {
        return; // Exit early if display or displayCode is null
      }

      Object.assign(this, { display, displayCode });

      const hotelListParams = {
        checkInDate: moment().format('YYYY-MM-DD'),
        checkOutDate: moment().add(3, 'days').format('YYYY-MM-DD'),
        adultNum: 1,
        cityCode: displayCode,
        keyWords: this.optionName,
        hotelStar: '4,5',
        pageNo: 1,
        pageSize: 6,
        sortCode: 0, // 0：默认 1：低价优先 2：高价优先 3：距离优先 4：好评优先
      };

      try {
        const res = await this.$axios.post(
          '/api/aliTripApi/hotelList',
          hotelListParams
        );

        if (_.get(res, 'code') === ResponseCode.SUCCESS) {
          this.hotelList = _.get(res, 'data.body.module.items', []);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  head(): Record<string, any> {
    const {
      exhibitionId,
      lunchTime,
      exhibitionName,
      exhibitionEnglishName,
      pavilionInfo,
      organizer,
      pic,
      industry = '',
    } = this.$data.detail;
    const { areaName, pavilionAddress } = pavilionInfo || {};
    const _year = lunchTime?.slice(0, 4);

    const _keyword = [];
    const nameEn = exhibitionEnglishName?.trim() || '';
    try {
      _keyword.push(nameEn);
      const industryList = industry?.split(',') || [];
      industryList.forEach((name: string) => {
        _keyword.push(
          `${areaName}${name}${this.$t('tdk.exhibitionDetail.keyword1')}`
        );
        _keyword.push(
          `${areaName}${name}${this.$t('tdk.exhibitionDetail.keyword2')}`
        );
        _keyword.push(
          `${areaName}${name}${this.$t('tdk.exhibitionDetail.keyword3')}`
        );
        _keyword.push(
          `${areaName}${name}${this.$t('tdk.exhibitionDetail.keyword4')}`
        );
        _keyword.push(
          `${areaName}${name}${this.$t('tdk.exhibitionDetail.keyword5')}`
        );
      });
    } catch (error) {
      console.error(error);
    }
    const keywords = _keyword.join(',');
    let cover = require('~/assets/logo/logo.png');
    try {
      cover = JSON.parse(pic)[0];
    } catch (error) {
      if (process.client && 'fundebug' in window) {
        window.fundebug.notifyError(error);
      }
      console.error(error);
      cover = require('~/assets/logo/logo.png');
    }
    // const titleName = nameEn?.length >= 20 ? '' : `_${nameEn}`;
    const title = `${exhibitionName}${_year}_${this.$t('tdk.ticket')}_${this.$t(
      'tdk.time'
    )}_${this.$t('tdk.magazine')}-${this.$t('tdk.jufair')}`;
    const description = `${this.$t('tdk.fairTime')}:${lunchTime},${this.$t(
      'tdk.address'
    )}:${pavilionAddress},${this.$t('tdk.sponsor')}:${organizer},${this.$t(
      'tdk.exhibitionDetail.description'
    )}.`;
    const aliplayer =
      'https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css';
    return {
      title,
      meta: [
        {
          name: 'mobile-agent',
          'http-equiv': 'mobile-agent',
          content: `format=html5; url=https://m.jufair.com/exhibition/${exhibitionId}.html`,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'og:type',
          content: 'product',
        },
        {
          name: 'og:image',
          content: cover,
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: description,
        },
        {
          name: 'og:url',
          content: `https://www.jufair.com${this.$route.path}`,
        },
        {
          name: 'og:product:price',
          content: this.booth?.boothPrice1,
        },
        {
          name: 'og:product:category',
          content: `${industry}${this.$t('common.fair')}`,
        },
        {
          name: 'og:product:nick',
          content: `name=${this.$t('tdk.jufair')}; url= https://www.jufair.com${
            this.$route.path
          } `,
        },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: aliplayer,
        },
      ],
      script: [
        {
          charset: 'utf-8',
          type: 'text/javascript',
          src: 'https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js',
          rel: 'prefetch',
        },
      ],
    };
  },
});
